<template>
  <q-expansion-item
    expand-separator
    icon="festival"
    class="border rounded q-mb-xs"
    :label="$t('Setup your warehouse')"
    :caption="$t('Set your warehouse settings like name, location, currency, weight and length units')"
  >
    <q-card>
      <q-card-section class="q-pb-xs">
        <warehouse-form
          ref="warehouseForm"
          :has-parent="hasParent"
          :has-type="hasType"
        />
      </q-card-section>

      <q-card-actions class="row justify-center q-pt-xs q-pb-md">
        <q-btn
          color="light-blue-9"
          style="min-width: 30%;"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-expansion-item>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import WarehouseForm from '../warehouse-form/WarehouseForm.vue'

export default {
  name: 'WarehouseTile',
  emits: ['submit'],
  components: {
    WarehouseForm
  },
  props: {
    defaultState: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      hasParent: false,
      hasType: false
    }
  },
  computed: {
    ...mapGetters([
      'warehouse',
      'isAdministrator',
      'isSupervisior'
    ])
  },
  mounted () {
    this.setNewWarehouse()
    this.updateWarehouse(this.defaultState)
    this.hasType = !!this.warehouse.type
    const owner = JSON.parse(localStorage.getItem('userData'))
    this.updateWarehouseEmbedded({ owner })

    if (this.warehouse.type === 'virtual') {
      const query = {
        per_page: 5,
        page: 1,
        fields: ['extended'],
        filter: [
          { type: 'eq', field: 'type', value: 'fulfillment' },
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      return this.$service.warehouse.getAll(query)
        .then(({ items, totalItems }) => {
          if (totalItems === 1) {
            const parent = items[0]

            this.updateWarehouseEmbedded({
              parent,
              country: this.getValue(parent.country),
              currency: this.getValue(parent.currency),
              length: this.getValue(parent.length),
              weight: this.getValue(parent.weight)
            })

            this.hasParent = true
          }
        })
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setNewWarehouse',
      'updateWarehouseEmbedded',
      'updateWarehouse'
    ]),
    ...mapActions([
      'saveWarehouse'
    ]),
    getValue (val) {
      return typeof val === 'string'
        ? JSON.parse(val)
        : val
    },
    // Get entities which we create automatic. We shouldn't create this entities from wizard
    getAutomaticCreatedEntities () {
      const entitiesByDomain = window.appOptions.automaticCreatedEntities || {}
      const entities = entitiesByDomain.all || entitiesByDomain[window.appOptions.domain] || {}

      return Object.keys(entities)
    },
    getOwner () {
      if ((this.isAdministrator || this.isSupervisior) && this.warehouse._embedded.owner) {
        return this.warehouse._embedded.owner.id
      }

      return undefined
    },
    saveNewSender (warehouse) {
      const data = {
        name: warehouse.name,
        owner: this.getOwner()
      }

      return this.$service.sender.save(data)
    },
    saveNewShop (warehouse) {
      const data = {
        name: warehouse.name,
        owner: this.getOwner()
      }

      return this.$service.shop.save(data)
    },
    save () {
      const fields = this.$refs.warehouseForm.getFieldsWithoutValue()

      if (fields.length > 0) {
        fields.forEach(field => {
          this.addErrorNotification(`Field ${field.label} is required!`)
        })

        return
      }

      return this.saveAll()
        .catch(err => {
          this.addErrorNotification(err)
        })
    },
    getId (entity = {}) {
      if (!entity) {
        return undefined
      }

      return entity.id
    },
    getPlace ({ warehouse, type, parent = null }) {
      return {
        dimensions: { x: 0, y: 0, z: 0 },
        placeAlias: `Test ${type}`,
        rack: 0,
        room: 0,
        row: 0,
        shelf: 0,
        type,
        volume: 0,
        parent: this.getId(parent),
        warehouse: this.getId(warehouse)
      }
    },
    savePlaces (places, results = []) {
      if (places.length <= 0) {
        return Promise.resolve(results)
      }

      return this.$service.storagePlace.save(places[0])
        .then(place => {
          results.push(place)
          return this.savePlaces(places.slice(1), results)
        })
    },
    createChildrenPlaces (types, { warehouse, parent }, results = []) {
      if (types.length <= 0) {
        return Promise.resolve(results)
      }

      return this.$service.storagePlace.save(this.getPlace({ warehouse, type: types[0], parent }))
        .then(place => {
          results.push(place)
          return this.createChildrenPlaces(types.slice(1), { warehouse, parent }, results)
        })
    },
    createRoom (warehouse) {
      return this.$service.storagePlace.save(this.getPlace({ warehouse, type: 'room' }))
        .then(parent => {
          return this.$service.storagePlace.save(this.getPlace({ warehouse, type: 'section', parent }))
        })
        .then(parent => {
          const types = [
            'static',
            'dynamic',
            'employee',
            'sorting',
            'distribution',
            'distribution_rejected',
            'assembly',
            'universal',
            'pallet',
            'defected'
          ]
          return this.createChildrenPlaces(types, { warehouse, parent })
        })
    },
    saveAutomaticEntities (entities, results) {
      const saveFn = {
        sender: (defaultState) => {
          const data = {
            ...defaultState,
            name: this.warehouse.name,
            parent: this.warehouse._embedded.parent.eav['warehouse-sender'],
            owner: this.getOwner()
          }

          return this.$service.sender.save(data)
        },
        shop: (defaultState) => {
          const data = {
            ...defaultState,
            name: this.warehouse.name,
            owner: this.getOwner()
          }

          return this.$service.shop.save(data)
        }
      }

      if (entities.length <= 0) {
        return Promise.resolve(results)
      }

      const [key, defaultState] = entities[0]

      if (!saveFn[key]) {
        return this.saveAutomaticEntities(entities.slice(1), results)
      }

      return saveFn[key](defaultState)
        .then(result => {
          results.push(result)
          return this.saveAutomaticEntities(entities.slice(1), results)
        })
    },
    saveVirtualWarehouse () {
      return this.saveWarehouse()
        .then(warehouse => {
          return this.saveAutomaticEntities(Object.entries(this.getAutomaticCreatedEntities()))
            .then(() => {
              return warehouse
            })
        })
    },
    saveOtherWarehouse () {
      return this.saveWarehouse()
        .then(warehouse => {
          return this.createRoom(warehouse)
            .then(() => {
              return warehouse
            })
        })
    },
    saveAll () {
      if (!this.warehouse._embedded.parent && this.warehouse.type === 'virtual') {
        return Promise.reject(new Error('Fulfillment facility is required!'))
      }

      if (!this.warehouse.name) {
        return Promise.reject(new Error('You must set warehouse name!'))
      }

      if (this.warehouse._embedded.parent && this.warehouse._embedded.parent.eav && !this.warehouse._embedded.parent.eav['warehouse-sender']) {
        return Promise.reject(new Error('Warehouse don\'t have settings for parent sender!'))
      }

      return Promise.resolve(this.warehouse.type === 'virtual')
        .then(isVirtual => {
          return isVirtual
            ? this.saveVirtualWarehouse()
            : this.saveOtherWarehouse()
        })
        .then(warehouse => {
          this.$emit('submit', warehouse)
          return warehouse
        })
    }
  }
}
</script>
