<template>
  <div>
    <div class="row items-center">
      <div class="col-12 col-md-5 q-pa-xs">
        <q-select
            ref="localeSelect"
            :model-value="select.title"
            :options="typesОfStatistics"
            :label="$t('Chart')"
        >
          <template
              v-slot:option="props"
          >
            <div
                class="q-pa-sm card--clickable card--clickable-no-scale"
                :class="props.selected ? 'bg-success' : ''"
                @click="selectTypeOfChart(props.opt)"
            >
              {{ props.opt.title }}
            </div>
          </template>
        </q-select>
      </div>

      <div v-if="!dataSource" class="col-12 col-md-5 q-pa-xs">
        <q-select
            ref="localeSelect"
            :model-value="type.title"
            :options="types"
            :label="$t('Type')"
        >
          <template v-slot:option="props">
            <div
                class="q-pa-sm card--clickable card--clickable-no-scale"
                :class="props.selected ? 'bg-success' : ''"
                @click="selectTypeOfData(props.opt)"
            >
              {{ props.opt.title }}
            </div>
          </template>
        </q-select>
      </div>

      <div class="col text-right q-pa-xs">
        <q-btn
            no-caps
            color="dark"
            text-color="white"
            :label="$t('Filters')"
            @click="openCloseFilters"
        />
      </div>
    </div>

    <div>
      <filters-collapse
          ref="filterCollapse"
          :is-open="isOpenFilter"
          :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
      />
    </div>

    <div class="q-px-sm">
      <trend-bar-chart
          v-if="select.id === 'trend-chart'"
          :title="type.title"
          :values="trandStatistics"
      />

      <bar-chart
          v-if="select.id === 'bar-chart'"
          :data="statisticsTotal"
      />

      <q-table
          v-if="select.id === 'table'"
          :pagination="{ rowsPerPage: 250 }"
          :title="type.title"
          :rows="statisticsTable.rows"
          :columns="statisticsTable.columns"
          row-key="name"
      />


    </div>
  </div>
</template>

<script>

// Vuex
import { mapActions, mapGetters } from 'vuex'

// Mixins
import TableMixinVue from '../global/TableMixin.vue'

// Components
import BarChart from './BarChart.vue'
import FiltersCollapse from './../filters/FilterCollapse'
import TrendBarChart from './../trend-bar-chart/TrendBarChart'

// Helpers
import orderStatusMatrix from './../../config/OrderMatrix'

export default {
  name: 'StatisticPanel',
  components: {
    BarChart,
    FiltersCollapse,
    TrendBarChart
  },
  mixins: [
    TableMixinVue
  ],
  props: {
    dataSource: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      dataSources: {
        '/api/reports/statistics/delivery-services/delivery-services-delivery-requests': 'deliveryRequests',
        '/api/reports/statistics/products/products-orders': 'orders'
      },
      select: { id: 'trend-chart', title: this.$t('Trend Chart') },
      type: { id: 'deliveryRequests', title: this.$t('Shipping Requests') },
      isOpenFilter: false,
      typesОfStatistics: [
        { id: 'trend-chart', title: this.$t('Trend Chart') },
        { id: 'bar-chart', title: this.$t('Bar Chart') },
        { id: 'table', title: this.$t('Table') }
      ],
      types: [
        { id: 'orders', title: this.$t('Orders') },
        { id: 'deliveryRequests', title: this.$t('Shipping Requests') }
      ],

    }
  },
  computed: {
    ...mapGetters([
      'productsOrdersStatistics',
      'loadingNow',
      'statisticsTotal',
      'statisticsTable',
      'trandStatistics',
      'appOptions'
    ]),
    activatedFields () {
      const fields = {
        orders: [
          'created.from',
          'created.to',
          'shop'
        ],
        deliveryRequests: [
          'created.from',
          'created.to',
          'sender'
        ]
      }

      return fields[this.type.id]
    },
    statuses () {
      return orderStatusMatrix.reduce((acc, group) => {
        return [...acc, ...group.buttons]
      }, [])
    }
  },
  mounted () {
    if (this.appOptions.settings && this.appOptions.settings.defaultStatisticPanel) {
      this.type = this.appOptions.settings.defaultStatisticPanel
    }

    if (this.dataSource && this.dataSources[this.dataSource]) {
      this.type = this.types.find(x => x.id === this.dataSources[this.dataSource])
    }

    this.loadWithDefaultFilter()
  },
  methods: {
    ...mapActions([
      'loadProductsOrdersStatistics',
      'loadDeliveryRequestsStatistics'
    ]),
    selectTypeOfData (type) {
      this.type = type
      this.$refs.filterCollapse.handleReset()
      this.isOpenFilter = false
    },
    handleFiltersSubmit (filter) {
      this.updateParams({ filter })
      this.openCloseFilters()

      if (filter.length === 0) {
        this.loadWithDefaultFilter()
      } else {
        const loaders = {
          orders: this.loadProductsOrdersStatistics,
          deliveryRequests: this.loadDeliveryRequestsStatistics
        }
        loaders[this.type.id]({ filter })
      }
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    loadWithDefaultFilter () {
      const loaders = {
        orders: this.loadProductsOrdersStatistics,
        deliveryRequests: this.loadDeliveryRequestsStatistics
      }
      const from = this.createDate(new Date())

      if (from[1] === 1) {
        from[0]--
        from[1] = 12
      } else {
        from[1]--
        from[2]++
      }

      const date = this.createDate(new Date(from[0], from[1], 0))

      if (from[2] > date[2]) {
        from[2] = date[2]
      }

      const query = {
        filter: [
          {
            field: 'created',
            type: 'gte',
            value: from.join('-')
          }
        ]
      }

      this.updateParams(query)
      return loaders[this.type.id](query)
    },
    createDate (date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()}`.split('-').map(Number)
    },
    selectTypeOfChart (option) {
      this.select = option
    },
    rowStyleClass (row) {
      const types = {
        public: 'active'
      }

      return types[row.type]
    },

  }
}
</script>

<style>
@media screen and (max-width: 768px) {
  .btn.btn-statistic-filter {
    width: 100%;
  }
}
</style>
