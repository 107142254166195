<template>
  <q-expansion-item
    expand-separator
    icon="local_shipping"
    class="border rounded q-my-xs"
    :label="$t('Connect your shipping carrier')"
    :caption="$t('Connect to different delivery services via our integrations.')"
  >
    <q-card>
      <q-card-section>
        <d-s-integration @submit="handleSubmit" />
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
import DSIntegration from './../integration-wizard/DSIntegration'

export default {
  name: 'DSTile',
  emits: ['submit'],
  components: {
    DSIntegration
  },
  methods: {
    handleSubmit (item) {
      this.$emit('submit', item)
    }
  }
}
</script>
