<template>
  <div class="q-pa-md">
    <div id="bar-chart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3'

function getRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }

  return color
}

export default {
  name: 'BarChart',
  props: {
    width: {
      type: Number,
      default() {
        return window.innerWidth < 700
          ? window.innerWidth - 30
          : window.innerWidth - 280
      },
    },
    height: {
      type: Number,
      default() {
        return 300
      },
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    height() {
      this.draw()
    },
    width() {
      this.draw()
    },
    data() {
      this.draw()
    },
  },
  mounted() {
    this.draw()
  },
  methods: {
    draw() {
      const data = (this.data[0] || { values: [] }).values
      const margin = 30

      d3.select('#bar-chart').select('svg').remove()

      const svg = d3
        .select('#bar-chart')
        .append('svg')
        .attr('height', this.height)
        .attr('width', this.width)

      const chart = svg
        .append('g')
        .attr('height', this.height - margin)
        .attr('width', this.width - margin)
        .attr('transform', `translate(${margin}, -${margin})`)

      let max = [...data].sort((a, b) => b.value - a.value)[0].value
      max += Number(((30 / 100) * max).toFixed())
      const y = d3.scaleLinear().range([this.height, 0]).domain([0, max])

      chart
        .append('g')
        .attr('color', '#000')
        .attr('fill', '#000')
        .attr('style', 'font-size: 12px;')
        .attr('transform', `translate(${8}, ${0})`)
        .call(d3.axisLeft(y))

      const x = d3
        .scaleBand()
        .range([0, this.width])
        .domain(data.map((s) => s.label))
        .padding(0.2)

      chart
        .append('g')
        .attr('color', '#000')
        .attr('fill', '#000')
        .attr('transform', `translate(${0}, ${this.height})`)
        .attr('style', 'font-size: 12px;')
        .call(d3.axisBottom(x))

      chart
        .selectAll()
        .data(data)
        .enter()
        .append('rect')
        .attr('transform', `translate(${0}, ${0})`)
        .attr('x', (s) => x(s.label))
        .attr('y', (s) => y(s.value))
        .attr('height', (s) => this.height - y(s.value))
        .attr('width', x.bandwidth())
        .attr('fill', () => getRandomColor())
        .on('mouseenter', function () {
          d3.select(this).attr('opacity', 0.5)
        })
        .on('mouseleave', function () {
          d3.select(this).attr('opacity', 1)
        })

      chart
        .selectAll()
        .data(data)
        .enter()
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', (s) => x(s.label) + x.bandwidth() / 2)
        .attr('y', (s) => y(s.value) - 5)
        .attr('style', 'font-size: 14px; font-weight: 600;')
        .text((d) => {
          return d.value
        })

      chart
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0, ${this.height})`)
        .attr('color', '#000')
        .style('opacity', '.1')
        .call(
          d3.axisBottom().scale(x).tickSize(-this.height, 0, 0).tickFormat('')
        )

      chart
        .append('g')
        .attr('class', 'grid')
        .attr('color', '#000')
        .style('opacity', '.1')
        .call(
          d3.axisLeft().scale(y).tickSize(-this.width, 0, 0).tickFormat('')
        )
    },
  },
}
</script>
