<template>
  <div id="trend-chart" :style="`height: ${height}px`"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'TrendChart',
  props: {
    width: {
      type: Number,
      default () {
        const value = window.innerWidth < 700
          ? window.innerWidth - 30
          : window.innerWidth - 280
        return value / 2
      }
    },
    height: {
      type: Number,
      default () {
        return 130
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    height () {
      this.draw()
    },
    width () {
      this.draw()
    },
    data () {
      this.draw()
    }
  },
  mounted () {
    this.draw()
  },
  methods: {
    draw () {
      d3.select('#trend-chart').select('svg').remove()
      const sortedData = [...this.data].sort((a, b) => b.value - a.value)

      let max = sortedData[0].value
      let min = sortedData[sortedData.length - 1].value
      const y = d3.scaleLinear()
        .range([this.height, 0])
        .domain([max, min])

      const x = d3.scaleBand()
        .range([0, this.width])
        .domain(this.data.map(s => s.label))

      const data = this.data.map(v => ([x(v.label), y(v.value)]))

      const svg = d3.select('#trend-chart')
        .append('svg')
        .attr('height', this.height)
        .attr('width', this.width)

      const Gen = d3.area()
        .x((p) => p[0])
        .y0(() => 0)
        .y1((p) => p[1])
        .curve(d3.curveBasis)

      svg.append("path")
        .attr("d", Gen(data))
        .attr("fill", "rgba(255, 255, 255, 0.1)")
        .attr("stroke", "#39af78")
        .attr('height', this.height)
        .attr('width', this.width)
        .attr('transform', `rotate(180), translate(-${this.width}, -${this.height})`)
    }
  }
}
</script>
