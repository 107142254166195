<template>
  <q-dialog
    v-model="isOpen"
    :maximized="isFullscreen"
  >
    <q-card
      v-if="isOpen"
      class="relative"
      style="min-width: 40vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ integration._embedded.deliveryService.name + ' ' + $t('integration') }}
        </div>

        <q-space />

        <q-btn
          icon="close"
          flat
          round
          dense
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="specificSchema" />

        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-actions class="justify-center">
        <q-btn
          color="dark"
          size="sm"
          class="q-mr-sm"
          :label="$t('Close')"
          no-caps
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          size="sm"
          :label="$t('Save')"
          no-caps
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'DSIntegrationModal',
  emits: ['submit', 'close'],
  data () {
    return {
      isOpen: false,
      isFullscreen: false
    }
  },
  computed: {
    ...mapGetters([
      'integration',
      'integrations'
    ]),
    specificSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'name',
                value: this.integration.name,
                required: true,
                onChange: (name) => {
                  this.updateIntegration({ name })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'sender',
                value: this.integration._embedded.sender,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (sender) => {
                  this.updateIntegrationEmbedded({ sender })
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      const typesOfFields = {
        text: (setting) => {
          return {
            type: 'input',
            inputType: 'text',
            field: setting.name,
            value: this.integration.settings[setting.name],
            label: setting.options.label,
            wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
            onChange: (value) => {
              this.updateIntegrationSettings({ [setting.name]: value })
            }
          }
        },
        select: (setting) => {
          return {
            type: 'select',
            label: setting.options.label,
            field: setting.name,
            value: this.integration.settings[setting.name],
            wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
            options: Object.entries(setting.options.value_options).map(([id, name]) => ({ id, name })),
            customLabel: row => {
              return row && typeof row === 'object'
                ? row.name
                : row || ''
            },
            onChange: (value) => {
              this.updateIntegrationSettings({ [setting.name]: value.id })
            }
          }
        }
      }

      const fields = (this.integration._embedded.deliveryService.settingsList || []).map(setting => {
        return typeof typesOfFields[setting.type] === 'function'
          ? typesOfFields[setting.type](setting)
          : typesOfFields.text(setting)
      })

      return {
        groups: [
          {
            styleClasses: 'row',
            fields
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateIntegration',
      'setIntegrations',
      'updateIntegrationEmbedded',
      'updateIntegrationSettings'
    ]),
    ...mapActions([
      'saveIntegration'
    ]),
    save () {
      return this.saveIntegration()
        .then(integration => {
          if (this.integration.id) {
            const items = this.integrations.map(item => {
              return item.id === integration.id
                ? integration
                : item
            })

            this.setIntegrations(items)
          } else {
            this.setIntegrations([integration, ...this.integrations])
          }

          this.$emit('submit', integration)
          this.close()
        })
    },
    open () {
      this.isOpen = true

      if (!this.integration.id && this.integration._embedded.deliveryService && this.integration._embedded.deliveryService.settingsList) {
        this.integration._embedded.deliveryService.settingsList.forEach(setting => {
          if (setting.options && setting.options.default_value) {
            this.updateIntegration({ settings: { ...this.integration.settings, [setting.name]: setting.options.default_value } })
          }
        })
      }
    },
    close () {
      this.isOpen = false
      this.$emit('close')
    }
  }
}
</script>
