<template>
  <q-expansion-item
    expand-separator
    icon="person"
    class="border rounded q-mb-xs"
    :label="$t('Setup your sender')"
    :caption="$t('Set your sender name, phone, email and address.')"
  >
    <q-card>
      <q-card-section class="q-pb-xs">
        <sender
          no-header
          @submit="handleSubmit"
        />
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
// Components
import Sender from '../../pages/settings/Sender.vue'

export default {
  name: 'SenderTile',
  emits: ['submit'],
  components: {
    Sender
  },
  methods: {
    handleSubmit (item) {
      this.$emit('submit', item)
    }
  }
}
</script>
