<template>
  <div class="row items-center justify-center">
    <div class="col-12 text-h6 text-weight-bold q-pa-sm text-center">
      {{ $t('Quick create') }}
    </div>

    <div
      v-for="option in options"
      :key="option.id"
      class="q-pa-sm"
    >
      <router-link
        v-if="option.route"
        class="text-center border rounded q-px-md q-py-sm block clickable"
        style="text-decoration: none;"
        :class="option.color ? `bg-${option.color}` : ''"
        :to="option.route"
      >
        <div v-if="option.icon" class="q-mb-sm">
          <q-icon :name="option.icon" size="sm" color="light-blue-9" />
        </div>

        <div class="text-subtitle1 text-weight-bold text-white">
          {{ option.name }}
        </div>
      </router-link>

      <div
        v-else
        class="text-center border rounded q-px-md q-py-sm clickable"
        :class="option.color ? `bg-${option.color}` : ''"
        @click="handleClick(option)"
      >
        <div v-if="option.icon" class="q-mb-sm">
          <q-icon :name="option.icon" size="sm" color="light-blue-9" />
        </div>

        <div class="text-subtitle1 text-weight-bold text-white">
          {{ option.name }}
        </div>
      </div>
    </div>

    <invoice-modal ref="invoice" />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import InvoiceModal from '../modals/InvoiceModal.vue'

export default {
  name: 'QuickActionsPanel',
  components: {
    InvoiceModal
  },
  data () {
    return {
      allOptions: [
        {
          id: 'po',
          color: 'positive',
          name: `+ ${this.$t('Inbound order (PO)')}`,
          route: '/inbound/purchase-orders/entity/products'
        },
        {
          id: 'returnOrder',
          color: 'positive',
          name: `+ ${this.$t('Return')}`,
          route: '/outbound/orders/entity/return'
        },
        {
          id: 'order',
          color: 'positive',
          name: `+ ${this.$t('Outbound order')}`,
          route: '/outbound/orders/entity/simple'
        },
        {
          id: 'product',
          color: 'positive',
          name: `+ ${this.$t('Product')}`,
          route: '/products/entity/simple'
        },
        {
          id: 'invoice',
          color: 'info',
          name: `+ ${this.$t('Create an Invoice')}`,
          onClick: () => {
            this.$refs.invoice.open()
          }
        },
        {
          id: 'user',
          color: 'accent',
          name: `+ ${this.$t('Create a Customer portal')}`,
          route: '/customer-portals/entity?type=client'
        }
      ],
      options: []
    }
  },
  computed: {
    ...mapGetters([
      'isEmployee',
      'isSupervisior',
      'isAdministrator'
    ])
  },
  mounted () {
    if (!this.isSupervisior && !this.isAdministrator) {
      this.options = this.allOptions.filter(x => x.id !== 'user')
    } else {
      this.options = [...this.allOptions]
    }

    if (this.isEmployee) {
      this.options = this.options.filter(x => x.id !== 'returnOrder')
    }
  },
  methods: {
    handleClick (option) {
      if (option.route) {
        return this.$router.push(option.route)
      }

      if (typeof option.onClick === 'function') {
        return option.onClick()
      }
    }
  }
}
</script>
