<template>
  <div class="row trend-chart q-pt-sm q-mb-sm">
    <div class="col-sm-4 q-py-sm row items-end">
      <div class="q-pl-sm">
        <div class="text-h5 q-mb-xs">
          <strong>{{ info.value }}</strong>
        </div>

        <div class="text-h6 q-mb-xs">
          {{ $t(title) }}
        </div>

        <div class="text-subtitle2">
          <strong>{{ info.label }}</strong>
        </div>
      </div>
    </div>

    <div ref="container" class="col-sm-8">
      <div
        v-if="values.length > 0"
      >
        <trend-chart :data="values" :width="dimensions.width" />
      </div>

      <div
        v-else
        class="row fit justify-center items-center text-subtitle"
      >
        <strong>{{ $t('No data!') }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import TrendChart from './TrendChart.vue'

export default {
  name: 'TrendBarChart',
  components: {
    TrendChart
  },
  props: {
    title: {
      type: String,
      default () {
        return 'Unknown'
      }
    },
    values: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      info: {
        label: '',
        value: ''
      }
    }
  },
  computed: {
    dimensions () {
      if (!this.$refs.container) {
        const value = window.innerWidth < 700
          ? window.innerWidth - 30
          : window.innerWidth - 280
        return { width: value / 2, height: 130 }
      }

      return this.$refs.container.getBoundingClientRect()
    },
    datasets () {
      const set = {
        data: this.values.map(({ value }) => value),
        smooth: true,
        fill: true,
        showPoints: true
      }

      return [set]
    },
    totalValuesInfo () {
      return {
        label: this.values.length > 1
          ? `${this.values[0].label}/${this.values[this.values.length - 1].label}`
          : `${(this.values[0] || {}).label || ''}`,
        value: this.values.reduce((acc, x) => {
          return acc + x.value
        }, 0)
      }
    }
  },
  watch: {
    values () {
      this.info = this.totalValuesInfo
    }
  },
  mounted () {
    this.info = this.totalValuesInfo
  },
  methods: {
    onMouseMove (value) {
      if (value && value.index === -1) {
        return
      }

      if (value) {
        this.info = this.values[value.index]
      }
    }
  }
}
</script>

<style>
.trend-chart {
  border-bottom: 1px #39af78 solid;
  word-break: break-all;
}

.active-line {
  stroke: rgba(0, 0, 0, 0.2);
}

.point {
  display: none;
  fill: #39af77;
  stroke: #39af77;
}

.point.is-active {
  display: block;
}

.point.is-active {
  display: block;
}

.stroke {
  stroke: #39af77;
  stroke-width: 2;
}

.fill {
  fill: #39af77;
  opacity: 0.2;
}
</style>
